<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>Product Code</label>
            <InputText v-model="item.product_code" :class="{ 'p-invalid': errorEdit.sku_id }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.sku_id" >{{ errorEdit.sku_id[0] }}</small >
        </div>
         <div class="field">
            <label>Product Brand</label>
            <InputText v-model="item.product_brand" :class="{ 'p-invalid': errorEdit.product_brand }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.product_brand" >{{ errorEdit.product_brand[0] }}</small >
        </div>
         <div class="field">
            <label>Product Name</label>
            <InputText v-model="item.product_name" :class="{ 'p-invalid': errorEdit.product_name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.product_name" >{{ errorEdit.product_name[0] }}</small >
        </div>
         <div class="field">
            <label>Product Category</label>
            <Dropdown v-model="item.product_category" :options="dataDropdownProductCategory" optionLabel="name" optionValue="code" placeholder="Select Product Category"  :filter="true" :showClear="true" :class="{ 'p-invalid': errorEdit.product_category }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.product_category" >{{ errorEdit.product_category[0] }}</small>
        </div>
         <div class="field">
            <label>Product Type</label>
            <InputText v-model="item.product_type" :class="{ 'p-invalid': errorEdit.product_type }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.product_type" >{{ errorEdit.product_type[0] }}</small >
        </div>
         <div class="field">
            <label>Flavour</label>
            <InputText v-model="item.flavour_name" :class="{ 'p-invalid': errorEdit.flavour_name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.flavour_name" >{{ errorEdit.flavour_name[0] }}</small >
        </div>
         <div class="field">
            <label>UOM</label>
            <InputText v-model="item.uom" :class="{ 'p-invalid': errorEdit.uom }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.uom" >{{ errorEdit.uom[0] }}</small >
        </div>
         <div class="field">
            <label>Content</label>
            <InputNumber v-model="item.content" :model-value="parseFloat(item.content)" :class="{ 'p-invalid': errorEdit.content }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.content" >{{ errorEdit.content[0] }}</small >
        </div>
         <div class="field">
            <label>Status</label>
            <Dropdown v-model="item.product_status" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Select Status" :class="{ 'p-invalid': errorEdit.status }"/>
                <small class="p-invalid" style="color: red" v-if="errorEdit.status" >{{ errorEdit.status[0] }}</small>
        </div>
        <div class="field">
            <label>Image</label>
            <br>
            <input type="file" ref="fileInput" id="file" name="file" accept="image/*" @change="handleFileChange" required>
            <br>
            <small class="p-invalid" style="color: black">Please select an image if you want to update the picture.</small >
            <br>
            <small class="p-invalid" style="color: red" v-if="errorEdit.photo" >{{ errorEdit.photo[0] }}</small >
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownProductCategory: [
                {name: 'FOCUS', code: 'FOCUS'},
                {name: 'MSS', code: 'MSS'},
                {name: 'OTHERS', code: 'OTHERS'},
            ],
            dataDropdownStatus: [
                {name: 'ACTIVE', code: 'y'},
                {name: 'NON ACTIVE', code: 'n'},
            ],

            // edit
            editItemDialog: false,

            selectedFile: null,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        // 
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            let formData = new FormData();
            formData.append('product_id', this.item.product_id);
            formData.append('sku_id', this.item.product_code ?? '');
            formData.append('brand', this.item.product_brand ?? '');
            formData.append('product_name', this.item.product_name ?? '');
            formData.append('product_category', this.item.product_category ?? '');
            formData.append('product_type', this.item.product_type ?? '');
            formData.append('flavour_name', this.item.flavour_name ?? '');
            formData.append('uom', this.item.uom ?? '');
            formData.append('content', this.item.content ?? '');
            formData.append('status', this.item.product_status);
            formData.append('photo', this.selectedFile ?? '');

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/product/update',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
		},
    }
}
</script>