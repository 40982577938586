<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Product Code</label>
                <InputText v-model="forms.sku_id" :class="{ 'p-invalid': errorAdd.sku_id }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.sku_id" >{{ errorAdd.sku_id[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Product Brand</label>
                <InputText v-model="forms.brand" :class="{ 'p-invalid': errorAdd.brand }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.brand" >{{ errorAdd.brand[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Product Name</label>
                <InputText v-model="forms.product_name" :class="{ 'p-invalid': errorAdd.product_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.product_name" >{{ errorAdd.product_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Product Category</label>
                    <Dropdown dataKey="code" v-model="forms.product_category"
                        :options="dataDropdownProductCategory" :class="{ 'p-invalid': errorAdd.product_category }"
                        optionLabel="name" optionValue="code" placeholder="Select Product Category"
                        :filter="true" :showClear="true"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.product_category">{{
                        errorAdd.product_category[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Product Type</label>
                <InputText v-model="forms.product_type" :class="{ 'p-invalid': errorAdd.product_type }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.product_type" >{{ errorAdd.product_type[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Flavour</label>
                <InputText v-model="forms.flavour_name" :class="{ 'p-invalid': errorAdd.flavour_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.flavour_name" >{{ errorAdd.flavour_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>UOM</label>
                <InputText v-model="forms.uom" :class="{ 'p-invalid': errorAdd.uom }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.uom" >{{ errorAdd.uom[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Content</label>
                <InputNumber v-model="forms.content" :class="{ 'p-invalid': errorAdd.content }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.content" >{{ errorAdd.content[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Nepal MRP (NPR)</label>
                <InputNumber v-model="forms.price_npr" mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" :class="{ 'p-invalid': errorAdd.price_npr }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.price_npr" >{{ errorAdd.price_npr[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>PTD (Include VAT)</label>
                <InputNumber v-model="forms.price_db_vat" mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" :class="{ 'p-invalid': errorAdd.price_db_vat }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.price_db_vat" >{{ errorAdd.price_db_vat[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>PTR (Include VAT)</label>
                <InputNumber v-model="forms.price_ptr_vat" mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" :class="{ 'p-invalid': errorAdd.price_ptr_vat }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.price_ptr_vat" >{{ errorAdd.price_ptr_vat[0] }}</small >
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-6">
                <label for="image">Image</label>
                <br>
                <input type="file" ref="fileInput" id="file" name="file" accept="image/*" @change="handleFileChange" required>
                <small class="p-invalid" style="color: red" v-if="errorAdd.photo" >{{ errorAdd.photo[0] }}</small >
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownProductCategory: [
                {name: 'FOCUS', code: 'FOCUS'},
                {name: 'MSS', code: 'MSS'},
                {name: 'OTHERS', code: 'OTHERS'},
            ],

            // addNew
            forms: {
                sku_id: null,
                brand: null,
                product_name: null,
                product_category: null,
                product_type: null,
                flavour_name: null,
                uom: null,
                content: 0,
                price_npr: 0,
                price_db_vat: 0,
                price_ptr_vat: 0,
            },

            selectedFile: null,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            let formData = new FormData();
            formData.append('sku_id', this.forms.sku_id ?? '');
            formData.append('brand', this.forms.brand ?? '');
            formData.append('product_name', this.forms.product_name ?? '');
            formData.append('product_category', this.forms.product_category ?? '');
            formData.append('product_type', this.forms.product_type ?? '');
            formData.append('flavour_name', this.forms.flavour_name ?? '');
            formData.append('uom', this.forms.uom ?? '');
            formData.append('content', this.forms.content ?? '');
            formData.append('price_npr', this.forms.price_npr ?? '');
            formData.append('price_db_vat', this.forms.price_db_vat ?? '');
            formData.append('price_ptr_vat', this.forms.price_ptr_vat ?? '');
            formData.append('photo', this.selectedFile ?? '');

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/product/create',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.sku_id = null;
            this.forms.brand = null;
            this.forms.product_name = null;
            this.forms.product_category = null;
            this.forms.product_type = null;
            this.forms.flavour_name = null;
            this.forms.uom = null;
            this.forms.content = 0;
            this.forms.price_npr = 0;
            this.forms.price_db_vat = 0;
            this.forms.price_ptr_vat = 0;
            this.selectedFile = null;
        },
    }
}
</script>