<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Date Start</label>
            <Calendar :manualInput="false" v-model="dateStart" dateFormat="yy-mm-dd" icon="pi pi-calendar" :showIcon="true" :class="{ 'p-invalid': errorEdit.date_start }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.date_start" >{{ errorEdit.date_start[0] }}</small >
        </div>
         <div class="field">
            <label>NPR</label>
            <InputNumber mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.price_npr" :model-value="parseFloat(item.price_npr)"  :class="{ 'p-invalid': errorEdit.price_npr }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.price_npr" >{{ errorEdit.price_npr[0] }}</small >
        </div>
         <div class="field">
            <label>PTD</label>
            <InputNumber mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.price_ptd" :model-value="parseFloat(item.price_ptd)" :class="{ 'p-invalid': errorEdit.price_db } " disabled/>
            <small style="color: blue">Automatically Calculated</small>
            <small class="p-invalid" style="color: red" v-if="errorEdit.price_db" >{{ errorEdit.price_db[0] }}</small >
        </div>
         <div class="field">
            <label>PTD(VAT)</label>
            <InputNumber mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.price_ptd_vat" :model-value="parseFloat(item.price_ptd_vat)" :class="{ 'p-invalid': errorEdit.price_db_vat }" @keyup.enter="calculated"/>
            <small style="color: blue">Please press Enter after typing to calculate.</small>
            <small class="p-invalid" style="color: red" v-if="errorEdit.price_db_vat" >{{ errorEdit.price_db_vat[0] }}</small >
        </div>
         <div class="field">
            <label>PTR</label>
            <InputNumber mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.price_ptr" :model-value="parseFloat(item.price_ptr)" :class="{ 'p-invalid': errorEdit.price_ptr }" disabled/>
            <small style="color: blue">Automatically Calculated</small>
            <small class="p-invalid" style="color: red" v-if="errorEdit.price_ptr" >{{ errorEdit.price_ptr[0] }}</small >
        </div>
         <div class="field">
            <label>PTR(VAT)</label>
            <InputNumber mode="decimal" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.price_ptr_vat" :model-value="parseFloat(item.price_ptr_vat)" :class="{ 'p-invalid': errorEdit.price_ptr_vat }" @keyup.enter="calculated"/>
            <small style="color: blue">Please press Enter after typing to calculate.</small>
            <small class="p-invalid" style="color: red" v-if="errorEdit.price_ptr_vat" >{{ errorEdit.price_ptr_vat[0] }}</small >
        </div>
        <small style="color: red" >Note: If you do not press Enter, don't worry, it will be automatically calculated upon updating.</small >
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            
            dateStart: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        // 
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        },
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),

        date_start(){ 
            return moment(this.dateStart, 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        price_ptd(){
            return this.item.price_ptd_vat/1.13
        }
    },
    methods: {
        calculated(){
            // eslint-disable-next-line vue/no-mutating-props
            this.item.price_ptd = this.item.price_ptd_vat / 1.13;
            // eslint-disable-next-line vue/no-mutating-props
            this.item.price_ptr = this.item.price_ptr_vat / 1.13;

        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.calculated();

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/product/update-price',
                data: {
                    "product_id": this.item.product_id,
                    "price_npr": this.item.price_npr,
                    "price_db": this.item.price_ptd,
                    "price_db_vat": this.item.price_ptd_vat,
                    "price_ptr": this.item.price_ptr,
                    "price_ptr_vat": this.item.price_ptr_vat,
                    "date_start": this.date_start
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;

                    this.dateStart= null;

                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
		},
    }
}
</script>